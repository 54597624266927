/**
 * MinersFactory | team member protrait
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const TeamPortrait = () => {
    const data = useStaticQuery(graphql`
        query {
            teamPortrait: file(relativePath: { eq: "mf-icon.png" }) {
                childImageSharp {
                    fluid(maxWidth: 256) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <Img
            fluid={data.teamPortrait.childImageSharp.fluid}
            className="team-portrait"
        />
    )
}

export default TeamPortrait
