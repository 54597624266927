/**
 * MinersFactory | team page
 */

import React from "react"

import SubPage from "./../templates/subpage"
import SEO from "./../components/seo"
import TeamPortrait from "./../components/teamprotr"

const TeamPage = () => (
    <SubPage>
        <SEO
            title="Server-Team"
            description="Von der Server-Leitung bis zu unserem InGame-Support- und Build-Team - hier findest du eine Übersicht aller Menschen und Spieler, die an unserem Server mitwirken!"
        />
        <div className="container">
            <section>
                <h1>Unser Server-Team</h1>
                <div className="row">
                    <div className="col-md-3">
                        <TeamPortrait />
                        <h3>Hydragil</h3>
                        <h4>Owner (Management)</h4>
                    </div>
                    <div className="col-md-3">
                        <TeamPortrait />
                        <h3>LookItsAku</h3>
                        <h4>Owner (Teamleitung)</h4>
                    </div>
                    <div className="col-md-3">
                        <TeamPortrait />
                        <h3>RadBruecke92897</h3>
                        <h4>Admin (Team- / Supporter-Leitung)</h4>
                    </div>
                    <div className="col-md-3">
                        <TeamPortrait />
                        <h3>thejjplaying</h3>
                        <h4>Admin (Development-Leitung)</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <TeamPortrait />
                        <h3>Minhboy</h3>
                        <h4>Moderator</h4>
                    </div>
                    <div className="col-md-3">
                        <TeamPortrait />
                        <h3>EinsSlayrem</h3>
                        <h4>Supporter</h4>
                    </div>
                    <div className="col-md-3">
                        <TeamPortrait />
                        <h3>SALManuel</h3>
                        <h4>Supporter</h4>
                    </div>
                    <div className="col-md-3">
                        <TeamPortrait />
                        <h3>Pizzadaemon3689</h3>
                        <h4>Supporter</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <TeamPortrait />
                        <h3>Creavivi</h3>
                        <h4>Builder</h4>
                    </div>
                    <div className="col-md-3">
                        <TeamPortrait />
                        <h3>blvckvivi</h3>
                        <h4>Builder</h4>
                    </div>
                    <div className="col-md-3">
                        <TeamPortrait />
                        <h3>YoloboyHD22</h3>
                        <h4>Builder</h4>
                    </div>
                    <div className="col-md-3">
                        <TeamPortrait />
                        <h3>ThexAnNi</h3>
                        <h4>Contender</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <TeamPortrait />
                        <h3>NXDavidYT</h3>
                        <h4>Contender</h4>
                    </div>
                    <div className="col-md-3">
                        <TeamPortrait />
                        <h3>ySeranix</h3>
                        <h4>Contender</h4>
                    </div>
                    <div className="col-md-3">
                        <TeamPortrait />
                        <h3>note3crafter</h3>
                        <h4>Contender</h4>
                    </div>
                </div>
            </section>
        </div>
    </SubPage>
)

export default TeamPage
